<template>
  <div class="contactRM">
    <section>
      <div class="contactLeft">
        <h2>Contact Us</h2>
        <div>
          <ul>
            <li>
              <span> <i class="fas fa-map-marker-alt"></i> Address </span>
              {{ $t("companyAddress") }}
            </li>
            <li>
              <span> <i class="fas fa-phone-alt"></i> Phone </span> 02-713-5847
            </li>
            <li>
              <span> <i class="fas fa-envelope-open-text"></i> E-mail </span>
              info@reviewmind.com
            </li>
            <li>
              <span> <i class="fas fa-parking"></i> Parking </span>
              {{ $t("parking") }}
            </li>
          </ul>
          <p>{{ $t("partner_comment") }}</p>
          <a href="mailto:info@reviewmind.com">
            <!-- <a href="tel:02-713-5847"> -->
            <button>{{ $t("contactUs") }}</button>
          </a>
        </div>
      </div>
      <div id="map"></div>
    </section>
  </div>
</template>
<script>
import lang from "@/mixins/lang";
export default {
  mixins: [lang],
  created() {
    this.$store.dispatch("GNB_MENU", 5);
  },
  mounted() {
    window.kakao && window.kakao.maps ? this.initMap() : this.addScript();
  },
  methods: {
    initMap() {
      var container = document.getElementById("map");
      var options = {
        center: new kakao.maps.LatLng(37.539615253487575, 126.94675753980147),
        level: 3,
      };
      var map = new kakao.maps.Map(container, options);
      var marker = new kakao.maps.Marker({ position: map.getCenter() });
      marker.setMap(map);
    },
    addScript() {
      const script = document.createElement("script");
      /* global kakao */ script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        "http://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=3cc3c9cc2537292fd72be1bf27f55f6d";
      document.head.appendChild(script);
    },
  },
};
</script>
